import katex from "katex";
import axios, { AxiosProgressEvent } from "axios";
import { AxiosResponse } from "axios";

function filterMath(text: string, delim: string, display: boolean): string {
  let result = "";
  text
    .replaceAll("\\begin{tabular}", "\\begin{array}")
    .replaceAll("\\end{tabular}", "\\end{array}")
    .split(delim)
    .forEach((chunk, index) => {
      if (index % 2 == 0) {
        result += chunk;
      } else {
        result += katex.renderToString(chunk, {
          displayMode: display,
          throwOnError: false,
        });
      }
    });
  result = result.replaceAll("\n", " ");
  return result;
}

async function base64ToBlob(
  base64data: string,
  dataType: string
): Promise<Blob> {
  return fetch(`data:${dataType};base64,${base64data}`).then((result) =>
    result.blob()
  );
}

export async function initDownload(
  base64data: string,
  dataType: string,
  fileName: string
): Promise<void> {
  const blob = await base64ToBlob(base64data, dataType);
  const blobURL = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = blobURL;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobURL);
}

export function processMath(text: string): string {
  let result = "";
  if (text) {
    result = filterMath(text, "$", true);
    result = filterMath(result, "#", false);
    result = result.replaceAll("\n", "<br />");
  }
  return result;
}

export function appendAll(formData: FormData, data: Record<string, string>) {
  for (const key in data) {
    formData.append(key, data[key] ? data[key] : "");
  }
}

export function backendGet(
  path: string,
  doIfSuccess: (response: AxiosResponse) => void,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  doIfFail: () => void = () => {}
): void {
  try {
    axios.get(path).then((response) => {
      if (response.status === 200) {
        doIfSuccess(response);
        return;
      } else {
        throw Error(
          "GET response status: " + response.status + ". Expected 200."
        );
      }
    });
  } catch (error) {
    // console.error("Error calling backend with GET request: " + error);
  }
  doIfFail();
}

export async function backendDelete(
  path: string,
  expectCode: number,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  doIfSuccess: (response: AxiosResponse) => void = () => {}
) {
  try {
    const response = await axios.delete(path);

    if (response.status === expectCode) {
      doIfSuccess(response);
    } else {
      throw Error(
        "DELETE response status: " +
          response.status +
          ". Expected " +
          expectCode
      );
    }
  } catch (error) {
    // console.error("Error calling backend with DELETE request: ", error);
  }
}

export async function backendPost(
  path: string,
  formData: FormData,
  expectCode: number,
  doIfSuccess: (response: AxiosResponse) => void = () => {
    return;
  },
  doIfFailed: (response: AxiosResponse) => void = () => {
    return;
  },
  onDownloadProgress: (progressEvent: AxiosProgressEvent) => void = () => {
    return;
  }
) {
  try {
    const response = await axios.post(path, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      validateStatus: (status: number) => {
        return (
          status === expectCode ||
          status === 422 ||
          status === 404 ||
          status === 500
        );
      },
      onDownloadProgress: onDownloadProgress,
    });

    if (response.status === expectCode) {
      doIfSuccess(response);
    } else {
      doIfFailed(response);
    }
  } catch (error) {
    return;
  }
}

export function arrayMove(
  arr: unknown[],
  old_index: number,
  new_index: number
): unknown[] {
  const item = arr.splice(old_index, 1)[0];
  arr.splice(new_index, 0, item);
  return arr;
}

export function getCurrentDate(): string {
  return new Date().toLocaleDateString();
}

export function showTextInNewTab(text: string): void {
  const processedText = text.replace(/\n/g, "<br>");
  const newTab = window.open("", "_blank");
  const content = `
  <html>
    <head>
      <title> Syntax Errors </title>
    </head>
    <body>
      ${processedText}
    </body>
  </html>
  `;
  newTab?.document.write(content);
  newTab?.document.close();
}
